<template>
	<div>
		<notification-service
			ref="notification"
			:key="'notification-' + this.$store.state.util.strVersao"
		/>
		<session-service
			ref="session"
			:key="'session-' + this.$store.state.util.strVersao"
		/>
		<request-service
			ref="request"
			:key="'request-' + this.$store.state.util.strVersao"
		/>

		<div class="loader-container" style="display: none">
			<div class="loader"></div>
		</div>
		<router-view />
	</div>
</template>

<script>
import NotificationService from "@/service/notificationService";
import RequestService from "@/service/requestService";
import SessionService from "@/service/sessionService";

export default {
	name: "HomeView",
	components: {
		NotificationService,
		RequestService,
		SessionService,
	},
	data() {
		return {};
	},
	created() {},
	mounted() {
		this.$root.$notification = this.$refs.notification;
		this.$root.$session = this.$refs.session;
		this.$root.$request = this.$refs.request;
	},

	methods: {},
};
</script>
