const setVersao = (context, data) => {
	context.commit("setVersao", data);
};

const setMensagem = (context, data) => {
	context.commit("setMensagem", data);
};

const setConfiguracao = (context, data) => {
	context.commit("setConfiguracao", data);
};

export { setVersao, setMensagem, setConfiguracao };
