const SET_MENU = (state, value) => {
	state.menus = value;
};

const SET_MENU_RESUMIDO = (state, value) => {
	state.menuResumido = value;
};

const SET_MENU_COMPLETO = (state, value) => {
	state.menuCompleto = value;
};

const SET_ABA = (state, value) => {
	state.aba.lista = value;
};

const SET_ABA_ATIVA = (state, value) => {
	state.aba.currentIndex = value;
};

const SET_SIDEBAR = (state, value) => {
	state.boolSidebarAtivo = value;
};

export {
	SET_MENU,
	SET_MENU_RESUMIDO,
	SET_MENU_COMPLETO,
	SET_ABA,
	SET_ABA_ATIVA,
	SET_SIDEBAR,
};
