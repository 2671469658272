import { createApp } from "vue";
import "./plugins";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import cookie from "./plugins/cookie";
import utilidade from "./plugins/utilidade";
import socket from "./plugins/socket";

require("@/assets/scss/app.scss");
require("@/assets/css/app.css");
require("@/assets/css/font-awesome.css");
require("@/assets/css/print.css");

//GLOBAL
const app = createApp(App);

app.config.globalProperties.$cookie = cookie;
app.config.globalProperties.$utilidade = utilidade;
app.config.globalProperties.$socket = socket;

app.use(router);
app.use(store);

app.mount("#app");
