const SET_VERSAO = (state, value) => {
	state.strVersao = value;
};

const SET_MENSAGEM = (state, value) => {
	state.listaMensagens = value;
};

const SET_CONFIGURACAO = (state, value) => {
	state.configuracao = value;
};

export { SET_VERSAO, SET_MENSAGEM, SET_CONFIGURACAO };
